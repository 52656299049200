import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
 
import * as ROUTES from '../../constants/routes';
 
import SignOutButton from '../SignOut';

import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';
     
class Navigation extends Component {

  constructor(props) {
    super(props);

    this.state = { isMenuCollapsed: false};

  }

    toggleBox = () => {
    this.setState(prevState => ({ isMenuCollapsed: !prevState.isMenuCollapsed }));
    };

    onNavigation = (dest) => {
      this.props.firebase.logEvent('navigate_to',{dest: dest});
      if(this.props.onNavigateTo) {
        this.props.onNavigateTo(dest);
      }
    }

    isActiveHome = (match, location) => {
      var output = false;
      if(match.isExact) {
        if(location.search) {
          var destination = this.getQueryVariable('dst',location.search);
          if(destination !== null) {
            output = false;
          } else {
            output = true;
          }
        } else {
          output = true;
        } 
      } 
      return output;
    }
    isActiveLink = (target, match, location) => {
      var output = true;
      if(match && match.isExact) {
        output = true;
      } else {
        if(location.search) {
          var destination = this.getQueryVariable('dst',location.search);
          if(destination !== null) {
            output = target === destination;
          } else {
            output = false;
          }          
        } else {
          output = false;
        }
      }
      return output;
    }
    getQueryVariable = (variable,search) => {      
        var vars = search.replace('?','').split('&');
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split('=');
            if (decodeURIComponent(pair[0]) === variable) {
                return decodeURIComponent(pair[1]);
            }
        }      
      return null;
    }

    render() {
      const { isMenuCollapsed } = this.state;
      return <AuthUserContext.Consumer>
        {authUser =>
          authUser ?<ul>
          <li>
            <Link to={ROUTES.LANDING}>Landing</Link>
          </li>
          <li>
            <Link to={ROUTES.HOME}>Home</Link>
          </li>
          <li>
            <Link to={ROUTES.ACCOUNT}>Account</Link>
          </li>   
          <li>
            <SignOutButton />
          </li>
        </ul>: 
        <header>
          <nav className="navbar navbar-expand-sm bg-white fixed-top">
            <NavLink onClick={(ev) => this.onNavigation('home')} className="navbar-brand site-logo" to={ROUTES.LANDING} />              
            <button onClick={this.toggleBox} className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className={`navbar-collapse " ${isMenuCollapsed ? "" : "collapse"}`} id="collapsibleNavbar">
              <ul className="navbar-nav mx-auto">
                <li className="nav-item">
                  <NavLink className="nav-link" onClick={(ev) => this.onNavigation('home')} isActive={this.isActiveHome} activeClassName="active" to={ROUTES.LANDING}>Inicio</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" onClick={(ev) => this.onNavigation('services')} isActive={(match,location) => this.isActiveLink('services-section',match,location)} activeClassName="active" to={ROUTES.LANDING+"services-section"}>Tratamientos</NavLink>
                </li>            
                <li className="nav-item">
                  <NavLink className="nav-link" onClick={(ev) => this.onNavigation('contact')} isActive={(match,location) => this.isActiveLink('contact-section',match,location)} activeClassName="active" to={ROUTES.LANDING+"contact-section"}>Contacto</NavLink>
                </li>
              </ul>
            </div>
          </nav>
        </header>

        }
      </AuthUserContext.Consumer>;
    }
  }

export default withFirebase(Navigation);