import React, {Component} from 'react';
import { NavLink } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';

import { withFirebase } from '../Firebase';

class WeCallYou extends Component {
    constructor(props) {
      super(props);
      this.state = {allowSend: false, phone: '', name: '', accept_privacy_policy: false, success: false};
      this.handleChange = this.handleChange.bind(this);
    }
    handleChange(event) {   
        var accept_privacy_policy;
        var allowSend;
        var phone;
        var name;
        //Population
        if(event.target.type === 'checkbox') {
            accept_privacy_policy = event.target.checked;
            phone = this.state.phone?this.state.phone:'';
            name =  this.state.name?this.state.name:'';
        } else {
            if(event.target.id === 'name') {
                name = event.target.value; 
                phone = this.state.phone?this.state.phone:''; 
            } else {
                phone = event.target.value; 
                name =  this.state.name?this.state.name:'';
            }
               
            accept_privacy_policy= this.state.accept_privacy_policy?this.state.accept_privacy_policy:false;       
        }
        //Validation
        if(phone && phone.match(/^(\+34|0034|34)?[ -]*(6|7|8|9)[ -]*([0-9][ -]*){8}$/) 
        && (name && name.trim() !== '' && name.match(/^[a-zA-Z ]{2,30}$/))
        && (accept_privacy_policy === true) ) {
            allowSend = true;
        }  else {
            allowSend = false;
        }  
        //Updatation         
        this.setState({allowSend: allowSend, accept_privacy_policy: accept_privacy_policy, phone: phone, name: name})
    }

    sendFormHandler = (event) => {        
        event.preventDefault();
        const self = this;
        this.props.firebase.weCallYouForm(this.state.phone, this.state.name)
        .then(function(docRef) {

            if(self.props.onSuccess) {
                self.setState({ success: true });                 
                self.timeout = setTimeout(() => {      
                    self.props.onSuccess();      
                }, 3000);                
            }            
        })
        .catch(function(error) {
            if(self.props.onFail) {
                self.props.onFail();
            }
        });
    }

    render() {  
        if(this.state.success === true) {
            return <div>
                <h2>Genial</h2>
                <br />
                <div>Nos pondremos en contacto en breve</div>
            </div>
        } else {
            return <form>
            <h2>Te llamamos</h2>
            <br />
            <div className="form-group">
                <div className="row">
                <input id="name" className = "form-control" type="text" 
                maxLength="30"
                placeholder="Nombre"
                value={this.state.name} 
                onChange={this.handleChange} />
                </div>
                <div className="row">
                <input id="phone"  className="form-control" type="text" 
                            maxLength="9"
                            placeholder="Teléfono"
                            value={this.state.phone} 
                            onChange={this.handleChange} />
                </div>
            </div>
            <div className="row form-check">
                <div className="col">
                    <input type="checkbox" checked={this.state.accept_privacy_policy} onChange={this.handleChange} className="form-check-input" id="acceptPolicy" />
                    <label className="form-check-label" htmlFor="acceptPolicy">
                    Acepto la <NavLink onClick={this.props.onClose} to={ROUTES.PRIVACY_POLICY}>política de privacidad</NavLink></label>            
                </div>            
            </div>
            <br />
            <div className="row">
                <div className="col">
                    {
                    this.state.allowSend?<button className="btn btn-primary text-white" onClick={this.sendFormHandler}>Enviar</button>
                    :<button className="btn btn-primary text-white" disabled >Enviar</button> 
                    }
                </div>
                <div className="col">
                <button className="btn btn-primary text-white" onClick={this.props.onClose}>Cancelar</button>
                </div>
            </div>
           
            
        </form>;
        }     
    }
    componentWillUnmount() {
        if(this.timeout) {
            clearTimeout(this.timeout);  
        }        
    }
}

export default withFirebase(WeCallYou);