import React, {Component} from 'react';
import { withFirebase } from '../../../Firebase';

class Protesis extends Component {

    constructor(props) {
        super(props);
    
        this.state = {
            title: "Prótesis dental"       
        };    
      }

    componentDidMount() {
        this.props.firebase.logEvent('opening_article',{title: this.state.title});
    }

    render() {
        return <div className="site-section articulo">
            <div className="block-heading-1"><h2>{this.state.title}</h2></div>
            <p>Los tratamientos odontológicos en los últimos años, han obtenido un mayor reconocimiento dado el efecto positivo que aportan a la calidad de vida de las personas. Esto abarca la alta demanda que han presentado las prótesis dentales por los beneficios que proporciona al paciente que ha perdido total o parcialmente alguna de sus piezas dentales.</p>    
            <p>Las prótesis pretenden <b>regresar a la normalidad el aspecto y función de los dientes</b> comprometidos y mejorar la estética de los mismos. Pero ¿cómo funcionan exactamente?, y ¿cuáles son sus beneficios?</p> 
            <br />
            <img src="/images/protesis.jpg" alt="" ></img>
            <br />
            <h3>¿Qué son las prótesis dentales?</h3>
            <p>Una prótesis es una <b>pieza artificial personalizada</b> para cada paciente, se implanta en los dientes previamente moldeada en las dimensiones naturales. De esta manera recuperan el funcionamiento correcto de la dentadura.</p>
            <p>Sirven para restaurar partes de los dientes, perdida de dientes y dientes disfuncionales, debido a que son elementos biocompatibles y seguros.</p> 
            <p>Su fabricación se realiza con el material que aporte <b>la mayor semejanza al diente en cuestión</b> y que sea lo suficientemente resistente para cumplir con sus funciones básicas. Conocer dichos materiales es bastante interesante debido a las características que les aportan a las piezas dentales que usarás en tu vida cotidiana.</p>             
            <br />
            <h3>¿De qué están hechas las prótesis dentales?</h3>
            <p>Se convierte en un paso fundamental elegir el material adecuado para fabricar la prótesis, pues de él va a depender regresar las funciones de la pieza dental original.</p> 
            <p>Este material <b>debe ser de larga duración</b>, que evite enfermedades como las infecciones, debe soportar la corrosión, presentar alta resistencia compresiva y estéticamente adecuado. Inicialmente el titanio era el material ideal por cumplir con dichas exigencias, pero su color gris oscuro se convierte en un problema de estética. </p>
            <p>Por suerte, en los últimos años el material cerámico resulta ser el candidato perfecto que se ajusta a las exigencias, incluyendo la estética. El óxido de Zirconio combinado con otros componentes da un producto blanco opaco considerado como una cerámica.</p>             
            <br />
            <h3>Funciones de las prótesis dentales</h3>
            <p>La función principal es <b>restaurar las piezas dentales dañadas que afectan la anatomía</b> de la boca, rehabilitando funciones como masticar, hablar y degustar alimentos. Además, regresan la armonía de tu dentadura mejorando tu sonrisa y con ello subir tu autoestima.</p> 
            <p>Aporta estabilidad y retención para evitar caídas cuando se está comiendo o hablando, además de prevenir el movimiento de los dientes por la falta de uno de ellos. Aunque sus funciones más específicas varían dependiendo del tipo de prótesis que necesites para solventar el problema que presentes.</p>
            <br />
            <h3>Tipos de prótesis dental</h3>
            <p>Dependiendo de la necesidad que tengas, el dentista de tu confianza es el encargado de <b>estudiar la prótesis dental adecuada para tu recuperación.</b> Pueden ser prótesis fijas que no puedes retirar tu mismo o prótesis removibles para el efecto contrario.</p>            
            <h4>Prótesis fijas</h4>
            <p>En este caso, no se pueden extraer directamente por el paciente, sino que debe recurrir a un médico odontólogo para poder removerlas. Dependiendo del tratamiento odontológico puedes requerir de una prótesis dental fija de tipo:</p>
            <ul>
                <li><b>Corona</b>: usada para restaurar uno o varios dientes, en este proceso realiza un molde semejante en tamaño y color a la pieza original. Luego, se talla el diente para crear agarre al momento de poner la prótesis encima. </li>
                <li><b>Prótesis fija sobre implantes</b>: usada para recuperar la función de la arcada completa, en este proceso se requiere un implante previo en los huesos maxilares. Luego, se fija la prótesis con tornillos o cementadas al implante.</li> 
                <li><b>Prótesis híbridas sobre implantes</b>: usadas para restaurar una arcada completa, se atornilla al implante y se mantiene fija hasta acudir a limpieza con el dentista.</li>
            </ul>  
            <br />
            <h4>Prótesis removibles</h4>
            <p>Estás puedes ser extraídas por el paciente sin necesidad de un dentista, según sea el caso pueden ser prótesis dentales completas, parciales y esqueléticamente removibles. </p>
            <ul>
                  <li><b>Prótesis completamente removibles</b>: usadas cuando se deben restaurar todos los dientes, son mucosoportadas porque se sujetan a la encía.</li>
                  <li><b>Prótesis parcialmente removibles</b>: usadas cuando existe solo una parte desdentada, son mucodentosoportadas porque se sujetan a la encía y a los dientes.</li>
            </ul> 
            <br />
            <h3>Preguntas más frecuentes</h3>
            <p>Sea removible o fija, si estás pensando en colocarte una prótesis dental seguramente tendrás algunas inquietudes naturales. </p>
            <h4>¿Cuánto duran las prótesis dentales?</h4>
            <p>Varía dependiendo de las personas, normalmente su vida útil es entre 5 y 10 años con los debidos ajustes y cuidados que debes realizar con el paso del tiempo. Sin embargo, puede ser mucho mayor <b>dependiendo del material y del tipo de prótesis.</b></p>
            <h4>¿Cómo se cuida una prótesis dental?</h4>
            <p>Si es una prótesis fija, puedes usar con cuidado hilo dental para limpiar entre la prótesis y la encía. Si es una prótesis removible, limpiar con cepillo de nailon usando jabón neutro y dependiendo del caso, ponerla en agua para conservar su forma. </p>
            <h4>¿Cómo es el procedimiento? </h4>
            <p>Varía dependiendo del tipo y cantidad de prótesis que requieras, pero generalmente <b>requerirás al menos dos visitas a tu dentista.</b> Al principio se procederá a la toma de las impresiones dentales para la elaboración de una prótesis a medida y en posteriores visitas la misma será instalada.</p>                                              
            </div>
    }
}

export default withFirebase(Protesis);