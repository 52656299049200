import React, {Component} from 'react';
import { withFirebase } from '../../../Firebase';

class Implantologia extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
            title: "Implantología"       
        };    
      }
    componentDidMount() {
        this.props.firebase.logEvent('opening_article',{title: this.state.title});
    }

    render() {
        return <div className="site-section articulo">
            <div className="block-heading-1"><h2>{this.state.title}</h2></div>            
            <p> La implantología se ha convertido en la última década en una de las técnicas odontológicas de mayor alcance, ideal para casos donde el paciente ha perdido alguna de sus piezas dentales. </p>
            <p>Normalmente perdemos piezas dentales por diversas razones: caries, enfermedades o accidentes. Los implantes están entre las soluciones más seguras y eficaces que ofrece la odontología moderna para sustituir estos dientes perdidos. </p>
            <p>El éxito de los implantes dentales viene dado porque no solo están hechos con fines estéticos, sino que <b>cumplen perfectamente con la función de la pieza dental perdida</b>. Descubre cómo funciona y cuáles son las principales ventajas que esta innovadora técnica puede aportarte.</p>
            <br />
            <img src="/images/implantologia.jpg" alt="" ></img>
            <br />
            <h3>¿De qué se trata?</h3>
            <p>La implantología es en realidad una rama de la odontología cuya misión es analizar y comprender a fondo la superficie y composición de los huesos maxilares. Esto con la finalidad de proceder a la colocación de prótesis dentales que devuelvan la funcionalidad y estética a la dentadura.</p>
            <p>Estos implantes colocados buscan <b>sustituir la pieza perdida</b>, copiando sus dimensiones naturales para evitar alterar la estructura de los demás dientes. </p>            
            <p>Funcionan como una raíz artificial: se unen al hueso maxilofacial mediante una cirugía sencilla. Esto permite crear el soporte que necesitan los nuevos dientes. </p>
            <p>Tras varios meses de esta cirugía (entre dos y seis, dependiendo de cada paciente) se da lo que se conoce como osteointegración; es decir, estas piezas se integran totalmente a la estructura ósea mediante un proceso natural.</p>
            <p>Sobre los implantes se colocan los pilares, una base para los nuevos dientes, y sobre estos las prótesis definitivas, que se conocen como coronas. </p>
            <p>Con este tratamiento los pacientes obtienen resultados muy similares a su dentadura original, tanto en apariencia como en resistencia.</p>
            <br />
            <h3>¿Por qué debemos sustituir los dientes perdidos?</h3>
            <p>No solo se trata de razones estéticas. Ciertamente, cuando falta un diente o una muela, el resto de las piezas dentales pueden moverse para tratar de llenar el espacio vacío y se desfigura la sonrisa. Pero, adicionalmente, si el hueso deja de recibir el estímulo que se produce al masticar puede perder altura y grosor, lo que ocasiona <b>problemas de masticación y de movimiento de los dientes</b>.</p>
            <br />
            <h3>¿Cómo funcionan los implantes dentales?</h3>
            <p>Mediante una simple cirugía, consiste en la implantación de una pieza en los huesos maxilares. Gracias a la propiedad del titanio se produce un fenómeno conocido como osteointegración que esencialmente <b>hace crecer hueso nuevo</b> y fija firmemente la nueva pieza.</p>
            <p>Actualmente se aplican diferentes técnicas y modalidades para lograr los efectos buscados de acuerdo a las circunstancias de cada paciente. De esta forma, los tipos más comunes usados en implantología son:</p>
            
            <h4>Implante dental unitario</h4>
            <p>Es el tratamiento implantológico más sencillo y constituye la solución definitiva para reemplazar un diente perdido. </p>
            <p>Con este tratamiento se crea un diente nuevo completamente funcional: se sustituye la raíz, se coloca un pilar y luego la corona. </p>
            <p>Es el tratamiento implantológico más sencillo y constituye la solución definitiva para reemplazar un diente perdido. </p>
            <p>Con este tratamiento se crea un diente nuevo completamente funcional: se sustituye la raíz, se coloca un pilar y luego la corona. </p>            
            <p><b>Ventajas del implante dental unitario:</b></p>
            <p>Es un tratamiento independiente que no amerita tallar o adaptar los dientes contiguos que estén sanos. Por lo tanto, no compromete otras piezas.</p>            
            <p><b>Tipos de implante dental unitario:</b></p>
            <ul>
                <li><b>Sobre corona cementada.</b> La corona se fija usando cemento biocompatible.</li>
                <li><b>Sobre corona atornillada.</b> La corona se fija al implante mediante un tornillo.</li>
            </ul>
            <p><b>¿Cómo es la recuperación después de este tratamiento?</b></p>
            <p>Al tratarse de una cirugía muy sencilla, que tarda aproximadamente 15 minutos, el postoperatorio es rápido; los pacientes no suelen presentar dolor. En algunos casos puede haber un pequeño sangrado o una ligera inflamación que se alivian con medicación. Si el paciente sigue los consejos del dentista al pie de la letra, principalmente relacionados con higiene y consumo de ciertos alimentos, la recuperación se realiza en un tiempo muy breve.</p>
            <h4>Puente sobre implantes</h4>
            <p>Permite reponer varios dientes contiguos (generalmente más de tres) de forma fija y natural. De esta manera se evitan las incómodas prótesis removibles.</p>            
            <p><b>¿Qué diferencia un puente sobre implantes de un puente dental?</b></p>
            <p>Para colocar los puentes dentales se utilizan como pilares o bases los dientes naturales tallados para hacerlos más pequeños; sobre ellos se colocan las coronas. En los puentes sobre implantes, los pilares son los propios implantes, es decir, la raíz artificial fabricada en titanio. No en todos los casos se pueden aplicar los mismos tratamientos.</p>            
            <p><b>Beneficios del puente sobre implantes</b></p>
            <ul>
                  <li>Logra reproducir los dientes perdidos, tanto en apariencia como en resistencia.</li>
                  <li>Se mantiene fijo, lo que implica que el paciente no necesita retirarlo en ningún momento.</li>
                  <li>En caso de tener pérdida de dentadura completa, permite reproducir y sustituir cada diente de manera fija.</li>
            </ul>
                
            <h4>Implante de carga inmediata</h4>
            <p>Es un tratamiento que permite colocar una prótesis provisional fija el mismo día en que se realiza el implante o en un máximo de 24 horas posteriores a la intervención. </p>
            <p>Este tipo de implante es muy demandado por pacientes que han perdido piezas dentales con mucha visibilidad y que prefieren una rápida solución estética mientras se completa el proceso de osteointegración.</p>
            
            <p><b>¿Es una solución definitiva?</b></p>
            <p>No, es provisional; se usa mientras se completa el proceso de osteointegración, tras lo cual se coloca la prótesis definitiva. </p>
            
            <p><b>¿Todos los pacientes pueden tener un implante de carga inmediata?</b></p>
            <p>No. El dentista debe evaluar si el paciente es apto para este tipo de tratamiento, dependiendo de su estructura ósea.</p>
            
            <p><b>Ventajas del implante de carga inmediata</b></p>
            <ul>
                  <li>Permite a los pacientes adaptarse progresivamente al uso de prótesis. </li>
                  <li>El paciente no tendrá problemas para mostrar su dentadura, lo que influye en su autoestima y confianza.</li>
                  <li>Posibilita la recuperación parcial de la funcionalidad: masticar y hablar de manera correcta, de forma inmediata.</li>
            </ul>                        
            <br />
            <h3>Ventajas de la implantología dental</h3>
            <p>Como ya sabes los implantes dentales sustituyen casi a la perfección la pieza dental perdida, tanto en funcionalidad como en apariencia, ¿qué los convierte en la <b>alternativa número uno</b> para todos los pacientes?</p>
            <h4>Durabilidad asegurada</h4>
            <p>A diferencia de planchas o puentes comunes, un implante dental correctamente colocado es capaz de permanecer contigo <b>por el resto de tu vida</b>. Esto, gracias a ser insertados directamente en el hueso de tu mandíbula mediante tornillos especiales para la tarea. </p>
            <p>Además, su elaboración está pensada para resistir procesos naturales al igual que los demás dientes, por lo que no tendrás que preocuparte por nuevos implantes a futuro. </p>
            <h4>Sin alteraciones dentales</h4>
            <p>Especialmente diseñados para cuidar y proteger tu salud dental, los implantes odontológicos son capaces de cumplir su función sin alterar las demás piezas.</p> 
            <p>La implantología se encarga precisamente de estudiar el espacio disponible para insertar la pieza, tomando en cuenta la estructura de los dientes cercanos y muchos otros factores. <b>Logrando así un acabado perfecto</b> que, además de ser estético, no genera ninguna molestia.</p>
            <h4>Económico</h4>
            <p>Aunque a simple vista la implantología puede parecer un tratamiento costoso, se trata de una <b>primera y única inversión</b> que, con los cuidados naturales, asegura durar toda tu vida.</p> 
            <p>Las planchas suelen ser una solución rápida y económica al principio, pero debido a su poca durabilidad, terminan convirtiéndose en grandes gastos recurrentes luego de un tiempo. </p>
            <br />
            <h3>Preguntas más frecuentes</h3>
            <p>Estas son algunas de las inquietudes más comunes que te pueden surgir al pensar en optar por este procedimiento.</p>
            <h4>¿Quiénes pueden optar por un procedimiento de este tipo?</h4>
            <p>En principio <b>cualquier persona puede optar por los implantes</b> dentales, ya sea por problemas patológicos o necesidades estéticas. Aun así, existen diferentes condiciones médicas que el especialista deberá tomar en cuenta y como mínimo deberás haber cumplido plenamente tu desarrollo físico.</p>
            <h4>¿Los implantes requieren un cuidado especial?</h4>
            <p>En absoluto. Una vez fijos basta con que los cuides adecuadamente, realiza una limpieza correcta junto con el resto de tus dientes y visita periódicamente a tu dentista de confianza.</p>
            <h4>¿Son dolorosos o provocan algún tipo de molestia?</h4>
            <p>Como con cualquier otra intervención, la única molestia es la relacionada directamente con la cirugía. Por supuesto, gracias a la anestesia la ausencia de dolor está garantizada. </p>
            <p>Por otra parte, <b>una vez la pieza ha sido fijada no la notarás</b> de ahí en adelante y puedes contar con que no genere ningún tipo de inconveniente.</p>
        </div>
    }
}

export default withFirebase(Implantologia);