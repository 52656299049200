import React, {Component} from 'react';
import { withFirebase } from '../../../Firebase';

class OdontologiaGeneral extends Component {

    constructor(props) {
        super(props);
    
        this.state = {
            title: "Odontología general"       
        };    
      }

    componentDidMount() {
        this.props.firebase.logEvent('opening_article',{title: this.state.title});
    }

    render() {
        return <div className="site-section articulo">
            <div className="block-heading-1"><h2>{this.state.title}</h2></div>
            <p>¿Te has preguntado por qué la odontología cobra fuerza en los últimos tiempos? Probablemente tus bisabuelos no visitaron nunca al dentista, pero ¿esto quiere decir que no lo necesitaban? ¡Por su puesto que no! De hecho, si haces algo de memoria o preguntas a tus padres notarás que probablemente sufrieron una perdida prematura de sus dientes.</p>
            <p>Hoy día se hace mucho mayor énfasis en el cuidado de la salud, no solo como reacción ante la enfermedad, sino como <b>prevención de la misma.</b> </p>
            <p>Ya sea por estética o por salud, por prevención o por tratamiento, la atención de los dientes presume estar fuera de riesgo de enfermedades que requieren tratamientos invasivos. Una asistencia rutinaria al dentista al menos una vez al año te asegura la salud oral.</p>            
            <br />
            <img src="/images/odontologia.jpg" alt="" ></img>
            <br /> 
            <h3>¿Cuáles son los tratamientos más comunes en odontología?</h3>
            <br />
            <p>La odontología es bastante amplia, desde restaurar un diente con caries hasta cirugías maxilofaciales, sin embargo, estadísticamente estos son los tratamientos más solicitados:</p>
            <h4>Restauraciones por caries</h4>
            <p>¿Sabes lo que son las caries? La placa bacteriana genera ácidos invasivos en los dientes, por ello cuando no existe una limpieza oportuna de las piezas dentales se genera la caries. Así es, esta enfermedad si es tratada de manera anticipada puede requerir una <b>restauración sencilla del diente</b> con amalgamas o resinas.</p>
            <p>Si no es detectada a tiempo avanza hasta afectar los nervios dentales llegando a la pulpa hasta necesitar de la endodoncia o la extracción de la pieza. En todo caso la odontología puede ayudar en la prevención, diagnóstico y cuidado para tener una salud oral efectiva.</p> 
            <h4>Endodoncias</h4>
            <br />
            <p>Seguramente has oído hablar de las personas con “tratamiento de conducto”, también conocido como “matar el nervio” a esto se le llama correctamente endodoncia. Este procedimiento se aplica cuando el diente es afectado con una caries no atendida que llega a infectar hasta el nervio. La solución para no perder la pieza es <b>eliminar total o parcialmente ese nervio</b> y sellar la zona afectada.</p>
            <p>Cuando el dentista aplica una endodoncia suele usar anestesia local, posteriormente extrae la pulpa, se encarga de limpiar y sellar. Algo que debes recordar es que las piezas dentales tratadas por este procedimiento pueden ser afectadas por caries nuevamente.</p>
            <p>Lo que se busca es no perder la pieza dental, solo un especialista en odontología puede diagnosticarlo y atenderlo adecuadamente, ya que si bien es sencillo también es bastante delicado.</p>            
            <h4>Periodoncia</h4>
            <br />
            <p>La periodontitis y la gingivitis son enfermedades que atiende la odontología en la especialización de periodoncia, la cual se encarga de tratar específicamente afectaciones en las encías. La principal causa de estas enfermedades es la <b>proliferación de bacterias</b>, cuando la placa se transforma en sarro y la higiene bucal es deficiente.</p>
            <p>Entre los tratamientos más comunes está la limpieza dental, el dentista retirará el sarro, la placa bacteriana y te brindará orientación para cumplir con una adecuada higiene oral.</p>             
            <br />
            <h3>¿Qué hacer para que la odontología no me genere temor?</h3>
            <p>El temor al ir al dentista es algo tan extendido que ya la <b>línea entre verdad y mito es bastante difusa.</b> Hay quienes temen debido a experiencias desagradables en alguna consulta médica, pero te sorprendería saber que muchos solo temen porque todos le dicen que debería hacerlo. Incluso niños que van por primera vez pueden tener miedo, ya que en la televisión o incluso sus padres lo impulsaron a ello.</p>
            <p>Desentrañando un poco el asunto del temor encontramos que a pesar de que la prevención está más difundida, muchos van al dentista ya cuando presentan algún tipo de dolor. Esta práctica no es recomendable, ya que resulta mucho más complicado realizar algún tratamiento cuando ya existe un problema a una <b>consulta de control que te lo habría evitado.</b></p>
            <p>Por otra parte, la odontología ha avanzado notablemente en la última década, los procedimientos son cada vez menos invasivos y consideran la comodidad del paciente. </p>                      
            </div>
    }
}

export default withFirebase(OdontologiaGeneral);