import React, {Component} from 'react';

class WeCallYouShortcut extends Component {
    constructor(props) {
      super(props);
      this.state = {};
      this.handleChange = this.handleChange.bind(this);
    }
    handleChange(event) {   
    }
    render() {
        return <div onClick={this.props.weCallYouTrigger} className="float-right wecallyou-shorcut">
            <span className="icon">
                    <span className="flaticon-telephone"></span>
            </span>
        </div>
    }
}

export default WeCallYouShortcut;