import React, { Component} from 'react';

const contactInfo = {
  phone: process.env.REACT_APP_CLINIC_PHONE,
  email: process.env.REACT_APP_CLINIC_EMAIL
};

class ContactSection extends Component {
      constructor(props) {
        super(props);     
        this.state = { ...contactInfo };
      }
      render() {
          return  <div className="site-section bg-light" id="contact-section">
          <div className="container">
                  <div className="row mb-5">
                    <div className="col-12">
                      <div className="block-heading-1">
                        <span></span>
                        <h2>Contacto</h2>
                      </div>
                    </div>
                  </div>
            <div className="row">
              <div className="col-lg-4 ml-auto">
                
                <iframe title="Ubicación de Denttop Getafe" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3042.382121611004!2d-3.7325659846072834!3d40.31167617937748!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd3eed4f6f5242b8!2sDenttop!5e0!3m2!1ses!2ses!4v1601984370919!5m2!1ses!2ses" width="350" height="300" frameBorder="0" allowFullScreen=""></iframe>
                
              </div>          
              <div className="col-lg-6 mb-5">
              <a href={"tel:"+this.state.phone}>
              <div className="block-feature-1 block-feature-2">
                  <span className="icon">
                    <span className="flaticon-whatsapp"></span>
                  </span>
                  <h2 className="text-black">{this.state.phone}</h2>
                </div>
                </a>
                <a href={"mailto:"+this.state.email}>    
                <div className="block-feature-1 block-feature-2">
                  <span className="icon">
                    <span className="flaticon-email"></span>
                  </span>
                  <h2 className="text-black">{this.state.email}</h2>
                </div>
                </a>
                <div className="block-feature-1 block-feature-2">
                <span className="icon">
                    <span className="flaticon-telephone"></span>
                  </span>
                <div className="text-black">O si lo prefieres,&nbsp; 
                <span><button className="link-button" onClick={this.props.weCallYouTrigger}>te llamamos</button></span>
                </div>               
              </div>	            	            	  
              </div>
            </div>
          </div>
          </div>;
      }
}

export default ContactSection;