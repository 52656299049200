import React, { Component } from 'react';
import { withFirebase } from '../Firebase';

class Footer extends Component {
    onSocialMedia = (dest) => {
        this.setState({ modalIsOpen: true});
        this.props.firebase.logEvent('social_media',{dest: dest});
      }
    render() {      
        return <footer className="site-footer">
        <div className="container">
        <div className="row">
            <div className="col-md-6">
            </div>
            <div className="col-md-4 ml-auto">
                <h2 className="footer-heading mb-4">S&iacute;guenos</h2>               
                <a onClick={() => {this.onSocialMedia('facebook')}} href="https://www.facebook.com/clinica.denttop/" className="pl-3 pr-3"><span className="flaticon-facebook"></span></a>
                <a onClick={() => {this.onSocialMedia('instagram')}}  href="https://www.instagram.com/dent_top/?hl=es" className="pl-3 pr-3"><span className="flaticon-instagram"></span></a>
                <a onClick={() => {this.onSocialMedia('linkedin')}}  href="https://www.linkedin.com/company/denttop" className="pl-3 pr-3"><span className="flaticon-linkedin"></span></a>
                <a onClick={() => {this.onSocialMedia('twitter')}}  href="https://twitter.com/denttop" className="pl-3 pr-3"><span className="flaticon-twitter"></span></a>
            </div>
        </div>

        </div>
        </footer>;
    }
}

export default withFirebase(Footer);
