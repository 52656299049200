import React, {Component} from 'react';
import { withFirebase } from '../../../Firebase';

class Ortodoncia extends Component {
        constructor(props) {
            super(props);
        
            this.state = {
                title: "Ortodoncia"       
            };    
          }
    
        componentDidMount() {
            this.props.firebase.logEvent('opening_article',{title: this.state.title});
        }
    
        render() {
            return <div className="site-section articulo">
                <div className="block-heading-1"><h2>{this.state.title}</h2></div>
                <p>Todos quieren estar cómodos con su imagen en el espejo y una sonrisa que les haga sentir confiados es un gran punto a favor para la autoestima de cualquiera. Actualmente existen muchos tratamientos que ayudan a mejorar la estética dental, uno de ellos es la ortodoncia, pues proporciona armonía y simetría a tu sonrisa.</p>
                <p>Sin embargo, la estética no es el único motivo para acudir al ortodoncista, una mal-oclusión puede causarte muchos otros <b>problemas que afectarán tu salud bucal y calidad de vida. </b></p>
                <p>El resultado que se obtiene con un tratamiento de ortodoncia genera cambios positivos y significativos en la salud emocional, además de mejorar la relación entre el maxilar superior y la mandíbula. Atrévete a descubrir la función y beneficios que trae la práctica de este procedimiento odontológico.</p>
                <br />
                <img src="/images/ortodoncia.jpg" alt="" ></img>
                <br />
                <h3>¿Qué es la ortodoncia?</h3> 
                <p>Es una especialidad de la odontología encargada de <b>corregir la posición de las piezas dentales</b> que se encuentran mal ubicadas causando distintos daños. Cuando tus dientes sufren de maloclusión, es decir crecen con los ángulos incorrectos (dientes torcidos, doblados, superpuestos o separados) se recurre a aplicar este tratamiento odontológico. </p>               
                <br />
                <h3>Efectos negativos de la mal-oclusión</h3>
                <p>Cuando no encajan correctamente tus dientes, puede generar bruxismo, con esta afección ocurre un desgaste entre los dientes que buscan crear un contacto más cómodo entre ellos. Además, debido a que dificulta la higiene bucal puede ocasionar caries o gingivitis y también <b>generar dolor en la articulación temporomandibular.</b></p>
                <p>Conocer el funcionamiento de este tratamiento dental te ayuda a estar informado antes de consultar el ortodoncista y así entender mejor el diagnóstico y procedimiento a seguir.</p>
                <br />
                <h3>Funcionamiento de la ortodoncia</h3>
                <p>Principalmente, el ortodoncista debe crear un diagnóstico mediante la realización de una radiografía, donde observa si hay más dientes por salir y en qué dirección. Luego del diagnóstico, procede a diseñar el tratamiento adecuado, en algunos casos cuando la sobremordida o sub mordida es seria se debe operar, pero <b>generalmente se corrige con aparatos ortodónticos.</b></p>
                <p>Existen distintos modelos de aparatos ortodónticos, pero todos cumplen con la misma función, aplicar una presión suave sobre los dientes para lograr que se muevan a una posición más adecuada. Estos pueden ser fijos o removibles según la recomendación indicada por el médico que está tratando tu caso:</p>
                <h4>Aparatos fijos </h4>
                <p>Los más utilizados en ortodoncia, consisten en un aparato fijo constituido por brackets cementados a los dientes. En ellos se inserta un alambre especial y finalmente se usan las ligas para mantener unido el bracket y el alambre. Su función es aplicar presión suave, <b>moviendo gradualmente los dientes a una posición correcta.</b></p>
                <h4>Aparatos removibles</h4>
                <p>En primera línea están los alineadores, siendo aparatos transparentes generalmente usados en adultos, deben retirarse para comer y se deben limpiar correctamente. </p>
                <p>Además, los aparatos de reposicionamiento de la mandíbula tienen la función de corregir la patología temporomandibular, cerrando la mandíbula a una posición adecuada. Por otra parte, los expansores de paladar sirven para ampliar el arco superior maxilar.</p>
                <p>Finalmente están los retenedores, su uso en la mayoría de los casos está después de los brackets, <b>evitando que los dientes vuelvan a su posición inicial. </b>Recuerda que los huesos tienen memoria. </p>
                <br />
                <h3>Beneficios de la ortodoncia</h3>
                <ul>
                      <li>Mejora el encaje de los dientes y su funcionamiento para masticar y digerir correctamente los alimentos.</li>
                      <li><b>Corrige la maloclusión dental</b> y al momento de cepillarse se limpian correctamente las encías evitando enfermedades.</li> 
                      <li>Se eliminan las patologías musculares y articulares de la cabeza y el cuello.</li>
                      <li>Con la correcta posición de los dientes la higiene es más sencilla <b>evitando periodontitis.</b></li>
                      <li>Mejora la fonación, logrando pronunciar correctamente las palabras.</li> 
                      <li><b>Obtiene una mejor sonrisa</b> que ayuda a cambiar la perspectiva de sí mismo y mejorar la autoimagen, mejorando la calidad de vida.</li>
                </ul>    
                <br />
                <h3>Cuidado de los dientes con aparatos ortodónticos </h3>
                <p>Si los dientes no se cepillan correctamente durante el tratamiento pueden ser dañados por acumulación de comida o placa, por lo que la higiene después de cada comida es obligatoria.</p>
                <p>Es recomendable tener un <b>enjuague bucal que contenga flúor,</b> que limpie lugares donde el cepillo de dientes no puede. En ocasiones los aparatos pueden causar irritación, por lo que se remienda usar ceras que protejan la encía en zonas de mucho roce.</p>
                <br />
                <h3>Preguntas más frecuentes</h3>
                <p>La ortodoncia es uno de los tratamientos odontológicos más comunes en la última década, sin embargo, aun muchas personas tienen <b>dudas al momento de dar este decisivo paso. </b></p>
                <h4>¿Cuánto dura este tratamiento?</h4>
                <p>Dependiendo de cada tratamiento y la complejidad del caso, tiene una <b>duración mínima de un año</b> para casos sencillos y en general entre dos y tres años para casos estándares.</p>
                <h4>¿Cuánto cuesta?</h4>
                <p>Los costos pueden variar dependiendo tu país y hasta de tu ciudad. Al momento de elaborar un presupuesto se considera el tipo de ortodoncia a utilizar y el tiempo estimado del tratamiento. De cualquier manera, suelen ofrecerse planes de pago fraccionados. </p>
                <h4>¿Duele tener los aparatos?</h4>
                <p>Inicialmente puede generar incomodidad y dolor, pero este <b>desaparece a los 3 o 4 días </b>después de aplicar presión en los dientes. </p>
                <h4>¿Cómo sé que debo usar aparatos?</h4>
                <p>La mejor manera de saber es acudiendo a una consulta médica con un profesional ortodoncista que te haga los estudios pertinentes.</p>                            
                </div> 
            }
}

export default withFirebase(Ortodoncia);