import React, {Component} from 'react';

const bussiness = {
    name: process.env.REACT_APP_CLINIC_NAME,
    cif: process.env.REACT_APP_CLINIC_CIF,
    address: process.env.REACT_APP_CLINIC_ADDRESS,
    phone: process.env.REACT_APP_CLINIC_PHONE,
    email: process.env.REACT_APP_CLINIC_LOPD_EMAIL
};

class PrivacyPolicy extends Component {

    constructor(props) {
        super(props);     
        this.state = { ...bussiness };
    }


    render() {
        return <div className="site-section block-feature-1-wrap" id="privacy_policy">
            <h3>Pol&iacute;tica de privacidad</h3>
            <br />
            <p>El objetivo de esta&nbsp;política es informar a los interesados acerca de los distintos tratamientos realizados por esta organización mediante la página web y que afecten a sus datos personales de conformidad con lo establecido en la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de los derechos digitales y el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo de 27 de abril de 2016.&nbsp;</p>
            <h4>Responsable del tratamiento </h4>
                <ul> 
                    <li><strong>Razón social</strong>: {this.state.name}</li> 
                    <li><strong>NIF</strong>: {this.state.cif}</li> 
                    <li><strong>Dirección:&nbsp;</strong>{this.state.address}</li> 
                    <li><strong>Teléfono</strong>: {this.state.phone}</li> 
                    <li><strong>Correo electrónico de contacto</strong>: {this.state.email}</li> 
                </ul>             
            <h4>Recopilación de la información</h4>
            <p>Trataremos sus datos de carácter personal facilitados a través de nuestros formularios web para:</p> 
                <ul>             
                    <li>Gestionar las solicitudes de petición de citas</li>             
                    <li>Entender el comportamiento del navegante dentro de la web con el fin de detectar posibles ataques informáticos a nuestra web.</li> 
                    <li>Cumplir con las obligaciones legales que nos resulten directamente aplicables y regulen nuestra actividad.</li> 
                    <li>Proteger y ejercer nuestros derechos o responder ante reclamaciones de cualquier índole.</li> 
                </ul> 
            <h4>Divulgación de información a terceros</h4>
            <p>La información recopilada por medio de la web no será informada a terceros, excepto a: </p>            
            <ul> 
            <li>Jueces y Tribunales.</li> 
            <li>Fuerzas y Cuerpos de Seguridad del Estado.</li> 
            <li>Otras autoridades u organismos públicos competentes, cuando el responsable tenga la obligación&nbsp;legal de facilitar los datos personales.</li> 
            </ul>             
            <h4>Retención de la información</h4>
            <p>Los datos personales proporcionados se conservarán durante el tiempo necesario para cumplir con las finalidades para los que fueron recopilados inicialmente.</p> 
            <h4>Derechos</h4>
            <p>Los interesados podrán ejercer en cualquier momento y de forma totalmente gratuita los derechos de acceso, rectificación y supresión, así como solicitar que se limite el tratamiento de sus datos personales, oponerse al mismo, solicitar la portabilidad de éstos (siempre que sea técnicamente posible) o retirar el consentimiento prestado, y en su caso, cuando proceda, a no ser objeto de una decisión basada únicamente en un tratamiento automatizado, incluido la elaboración de perfiles.</p> 
            <p>Para ello podrá emplear los formularios habilitados por la organización, o bien dirigir un escrito a la dirección postal o correo electrónico arriba indicadas. En cualquier caso, su solicitud deberá acompañarse de una fotocopia de su D.N.I. o documento equivalente, con la finalidad de acreditar su identidad.</p> 
            <p>En caso de que sienta vulnerados sus derechos en lo concerniente a la protección de sus datos personales, especialmente cuando no haya obtenido satisfacción en el ejercicio de sus derechos, puede presentar una reclamación ante la Autoridad de Control en materia de Protección de Datos competente (<strong>Agencia Española de Protección de Datos</strong>), a través de su sitio web: <a href="http://www.agpd.es" rel="external">www.agpd.es</a>.</p> 
            <p>En cumplimiento de lo dispuesto en el artículo 21 de la Ley 34/2002 de servicios de la sociedad de la información y comercio electrónico, si usted no desea recibir más información sobre nuestros servicios, puede darse de baja enviando un correo electrónico a la dirección {this.state.email} con asunto&nbsp; “<strong>BAJAS</strong>”.</p>             
            <h4>Consentimiento</h4>
            <p>El uso de la web supone la aceptación de esta política de privacidad</p>
            <h4>Actualización de esta política</h4>
            <p>La presente política de privacidad puede verse modificada/actualizada en función de las exigencias legales establecidas o con la finalidad de adaptar dicha política a las instrucciones dictadas por la Agencia Española de Protección de Datos o cambios en nuestro sitio web.</p> 
            <p>Por esta razón, aconsejamos a los usuarios que visiten periódicamente nuestra política de privacidad.</p>
        </div>;
    }
}

export default PrivacyPolicy;