export const LANDING = '/';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const HOME = '/home';
export const ACCOUNT = '/account';
export const ADMIN = '/admin';
export const PASSWORD_FORGET = '/pw-forget';
export const COOKIE_POLICY = 'cookie_policy';
export const PRIVACY_POLICY = 'privacy_policy';
export const SUBSECTION_ODONTOLOGIA_GENERAL = 'odontologia';
export const SUBSECTION_ENDODONCIA='endodoncia';

export const SUBSECTION_ORTODONCIA='ortodoncia';
export const SUBSECTION_IMPLANTOLOGIA='implantologia';
export const SUBSECTION_ESTETICA='estetica';
export const SUBSECTION_PROTESIS='protesis';