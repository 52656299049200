import React, { Component } from 'react';

class HomeSection extends Component {
      render() {
          return  <div id="home-section" className="site-section-cover img-bg-section">
          <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-5">
                  <div className="box-shadow-content">
                    <div className="block-heading-1">
                      <span className="d-block mb-3">Bienvenido a Denttop</span>
                      <h1 className="mb-4">Odontolog&iacute;a avanzada</h1>
                    </div>              
                    <p className="mb-4"></p>
                    <p ><button onClick={this.props.weCallYouTrigger} className="btn btn-primary text-white py-3 px-5">Te llamamos</button></p>
                  </div>
                </div>
            </div>
          </div>  
          </div>
      }
}

export default HomeSection;