
import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/analytics';

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  analytics_allowed: process.env.REACT_APP_ANALYTICS==='1'?true:false
};

  class Firebase {
    constructor() {
      app.initializeApp(config);
      this.auth = app.auth();
      this.db = app.firestore();      
      this.analyticsAllowed=config.analytics_allowed;
      if(this.analyticsAllowed === true) {
        this.analytics = app.analytics();
      }
    }
      // *** Auth API ***
 
  doCreateUserWithEmailAndPassword = (email, password) => {
    return this.auth.createUserWithEmailAndPassword(email, password);
  }
  doSignInWithEmailAndPassword = (email, password) => {
    return this.auth.signInWithEmailAndPassword(email, password);
  }
  doSignOut = () => {
    return this.auth.signOut();
  }
  doPasswordReset = email => {
    return this.auth.sendPasswordResetEmail(email);
  }
  doPasswordUpdate = password => {
    return this.auth.currentUser.updatePassword(password);
  }

  // *** We call you ***
 
  weCallYouForm = (phone, name) => {
  
    var created_at = app.firestore.FieldValue.serverTimestamp();
    return this.db.collection('wecallyou').add(
    {
      name: name?name.trim():"NO_NAME",
      phone: phone?phone.trim():"NO_PHONE",
      created_at: created_at
    }
  );}

  // ** analytics ** //
  logEvent = (key, data) => {
    if(this.analyticsAllowed === true) {
      var evKey = process.env.REACT_APP_ENV+"_"+key;
      this.analytics.logEvent(evKey,data);
    }
  }

}
   
  export default Firebase;
  
