import React, { Component } from 'react';
import Modal from 'react-modal';

import CookieAdvice from '../CookieAdvice';

import * as ROUTES from '../../constants/routes';
import CookiePolicy from '../CookiePolicy';
import WeCallYou from '../WeCallYou';
import WeCallYouShortcut from '../WeCallYouShortcut';
import PrivacyPolicy from '../PrivacyPolicy';
import HomeSection from './HomeSection';
import ServicesSection from './ServicesSection';
import ContactSection from './ContactSection';
import { withFirebase } from '../Firebase';
import OdontologiaGeneral from './ServicesSection/OdontologiaGeneral';
import Ortodoncia from './ServicesSection/Ortodoncia';
import Implantologia from './ServicesSection/Implantologia';
import Protesis from './ServicesSection/Protesis';
import Estetica from './ServicesSection/Estetica';

class LandingPage extends Component {

  constructor(props) {
    super(props);

    this.state = {       
      modalIsOpen: false, 
      modalStyles: {
      content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
      }} };  
      
      this.rootRef = React.createRef();
      this.homeRef = React.createRef();
      this.servicesRef = React.createRef();
      this.contactRef = React.createRef();

      const callback = entries => {
     
        entries.forEach(entry => {
          console.log(entry.target.id); 
          if(this.props.becomeVisible) {
            this.props.becomeVisible(entry.target.id);
          }          
        });        
      };
   
      this.observer = new IntersectionObserver(callback, {
        root: this.rootRef.current,
        threshold: 0.5
      });
  }

  handleWeCallYouModal = (src) => {
    this.setState({ modalIsOpen: true});
    this.props.firebase.logEvent('wecallyou_opened',{src: src});
  }

  handleOnSuccessWeCallYou = () => {
    this.setState({modalIsOpen: false});
    this.props.firebase.logEvent('wecallyou_success');
  }
  handleOnCloseWeCallYou = () => {
    this.setState({modalIsOpen: false});
    this.props.firebase.logEvent('wecallyou_closed');
  }

  render() {
    var section = this.props.match.params.section?this.props.match.params.section.replace('#',''):'';
    if(section === ROUTES.PRIVACY_POLICY) {
      return <PrivacyPolicy />;
    } else if(section === ROUTES.COOKIE_POLICY) {
      return <CookiePolicy />;
    } else {
      let querySubsectionSection = this.getQueryVariable('sb') || null;
      let sb = querySubsectionSection == null?this.props.match.params.subsection:querySubsectionSection;
      if(sb === ROUTES.SUBSECTION_ODONTOLOGIA_GENERAL) {
        return <OdontologiaGeneral />;
      } else if(sb === ROUTES.SUBSECTION_ENDODONCIA) {
        return <OdontologiaGeneral />;
      } else if(sb === ROUTES.SUBSECTION_ORTODONCIA) {
        return <Ortodoncia />;
      } else if(sb === ROUTES.SUBSECTION_ESTETICA) {
        return <Estetica />;
      } else if(sb === ROUTES.SUBSECTION_IMPLANTOLOGIA) {
        return <Implantologia />;
      } else if(sb === ROUTES.SUBSECTION_PROTESIS) {
        return <Protesis />;
      } else {
        return <div ref={this.rootRef}>
          <div id='home' ref={this.homeRef}>
          <HomeSection weCallYouTrigger = {(ev) => this.handleWeCallYouModal('home')}/>
          </div>
          <div id='services' ref={this.servicesRef} >
          <ServicesSection />
          </div>
          <div id='contact' ref={this.contactRef}>
          <ContactSection weCallYouTrigger = {(ev) => this.handleWeCallYouModal('contact')} />
          </div>
          <CookieAdvice />

        <Modal
            isOpen={this.state.modalIsOpen}
            contentLabel="Te llamamos"
            style={this.state.modalStyles}
          >
            <WeCallYou onClose = {this.handleOnCloseWeCallYou} onSuccess={this.handleOnSuccessWeCallYou} />
        </Modal>
        <WeCallYouShortcut weCallYouTrigger = {(ev) => this.handleWeCallYouModal('shorcut')} />
        </div>;
      }

    }
  }

  componentDidMount() {
    if(this.homeRef.current) {
      this.observer.observe(this.homeRef.current);
    }
    if(this.servicesRef.current) {
      this.observer.observe(this.servicesRef.current);
    }
    if(this.contactRef.current) {
      this.observer.observe(this.contactRef.current);
    }    
  }

  componentDidUpdate() {
    var section = this.props.match.params.section?this.props.match.params.section.replace('#',''):'';
    var el = null;
    if(section !== '') {
      el = document.getElementById(section);
    }
    else {      
      var querySection = this.getQueryVariable('dst');
      if(querySection !== null) {
        el = document.getElementById(querySection);
      }       
      if(el == null) {
        el = document.getElementById("home-section");
      }       
    }
    if(el) {
      el.scrollIntoView({behavior: 'smooth'});
    }
  }



  getQueryVariable = (variable) => {
    if(this.props.location.search) {
      var vars = this.props.location.search.replace('?','').split('&');
      for (var i = 0; i < vars.length; i++) {
          var pair = vars[i].split('=');
          if (decodeURIComponent(pair[0]) === variable) {
              return decodeURIComponent(pair[1]);
          }
      }
    }
    return null;
}
}

export default withFirebase(LandingPage);