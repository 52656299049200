import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import * as ROUTES from '../../../constants/routes';

const subsections = [
    /* Row 0*/[
      {
        name: "Implantología",
        icon: "flaticon-dental-implant",
        destination: ROUTES.LANDING+"services-section/"+ROUTES.SUBSECTION_IMPLANTOLOGIA
      },
      { name: "Prótesis dental",
        icon: "flaticon-dental-care",
        destination: ROUTES.LANDING+"services-section/"+ROUTES.SUBSECTION_PROTESIS
      },
      {
        name: "Ortodoncia",
        icon: "flaticon-dental",
        destination: ROUTES.LANDING+"services-section/"+ROUTES.SUBSECTION_ORTODONCIA
      }    
  ],
    /* Row 1*/[
      { name: "Estética dental",
      icon: "flaticon-tooth",
      destination: ROUTES.LANDING+"services-section/"+ROUTES.SUBSECTION_ESTETICA
    },
      {
        name: "Odontología general",
        icon: "flaticon-dental-care-1",
        destination: ROUTES.LANDING+"services-section/"+ROUTES.SUBSECTION_ODONTOLOGIA_GENERAL
      },      
      { name: "Endodoncia",
        icon: "flaticon-dental-care-2",
        destination: ROUTES.LANDING+"services-section/"+ROUTES.SUBSECTION_ENDODONCIA
      }                  
    ]
];

class ServicesSection extends Component {
  inflateServiceBlock = (name, icon, destination) => {
    return <div className="col-lg-4 col-md-6 mb-5">
                  <NavLink to={destination}>
                    <div className="block-feature-1">                    
                      <span className="icon">
                        <span className={icon}></span>
                      </span>
                      <h2 className="text-black">{name}</h2>                  
                    </div>
                  </NavLink>
                </div>;
  }
  createServices = () => {
    var output = [];
    subsections.forEach(row => {
      var c = [];
      row.forEach(subsection => {
        c.push(this.inflateServiceBlock(subsection.name, subsection.icon, subsection.destination))
      });
      output.push(<div className="row">{c}</div>);
    })
    return output;
  }
  render() {
      return <div className="site-section block-feature-1-wrap" id="services-section">
      <div className="container">
        <div className="row mb-5">
          <div className="col-12">
            <div className="block-heading-1">
              <span>Todas las áreas</span>
              <h2>Tratamientos</h2>
              <br />
              <p>Denttop es una clínica odontológica que ofrece un servicio integral con los más avanzados tratamientos odontológicos, tecnología de punta y profesionales preparados para garantizar la salud bucal al alcance de todos nuestros pacientes.</p>
            </div>
          </div>
        </div>
        {this.createServices()}
      </div>
    </div>
  }
}

export default ServicesSection;