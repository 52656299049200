import React, {Component} from 'react';

class CookiePolicy extends Component {
    render() {
        return <div className="site-section block-feature-1-wrap" id="cookie_policy">        <h3>Pol&iacute;tica de cookies</h3>
        <p>Las cookies son pequeñas cantidades de información que se almacenan en el navegador utilizado por cada usuario para que el servidor recuerde cierta información que posteriormente pueda utilizar.</p>
        <h4>Tipos de cookies que utilizamos</h4>
        <p>Esta página web utiliza cookies de terceros que son aquellas que se envían a tu ordenador o terminal desde un dominio o una página web que no es gestionada por nosotros, sino por otra entidad que trata los datos obtenidos a través de las cookies.</p>
        <p>En este caso las Cookies son utilizadas con fines estadísticos relacionados con las visitas que recibe y las páginas que se consultan, quedando aceptado su uso al navegar por ella.</p>
        <table className=""><tbody><tr><th>COOKIE (Y PROVEEDOR)</th><th>DURACIÓN</th><th>DESCRIPCIÓN</th></tr>            
            <tr><td>_ga (Google)</td><td>2 años</td><td>Se usa para distinguir a los usuarios.</td></tr>
            <tr><td>_gid (Google)</td><td>24 horas</td><td>Se usa para distinguir a los usuarios.</td></tr><tr><td>_gat (Google)</td><td>1&nbsp;minuto</td><td>Se usa para limitar el porcentaje de solicitudes. Si has implementado Google Analytics mediante Google&nbsp;Tag&nbsp;Manager, esta cookie se llamará _dc_gtm_&lt;property-id&gt;.</td></tr>
            <tr><td>_gali (Google)</td><td>30s</td><td>Atribución de enlace mejorada.</td></tr>
            <tr><td>Accept_cookies</td><td>Sesión</td><td>Se usa para saber que el usuario aceptó las cookies</td></tr>
            </tbody></table>
        <p>Si desea más información más sobre los tipos de cookies de seguimiento y análisis de datos de Google&nbsp;<a href="https://www.google.com/intl/es_es/policies/technologies/types/" className="rank-math-link" target="_blank" rel="noopener noreferrer">haga clic aquí</a>.</p>
        <p>Para informarse sobre cómo eliminar las cookies de su explorador:</p>
        <ul>
            <li><a href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias" className="rank-math-link" target="_blank" rel="noopener noreferrer">Firefox</a></li>
            <li><a href="https://support.google.com/chrome/answer/95647?hl=es" className="rank-math-link" target="_blank" rel="noopener noreferrer">Chrome</a></li>
            <li><a href="https://support.microsoft.com/en-us/topic/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d" className="rank-math-link" rel="noopener noreferrer">Internet Explorer</a></li>
            <li><a href="https://support.apple.com/kb/PH19214?viewlocale=es_ES&amp;locale=es_ES" className="rank-math-link" target="_blank" rel="noopener noreferrer" >Safari</a></li></ul></div>;
    }
}

export default CookiePolicy;