import React, {Component} from 'react';
 
import { withAuthorization } from '../Session';

import { compose } from 'recompose';
import { withFirebase } from '../Firebase';

const HomePage = () => (
  <div>
    <h1>Home Page</h1>

    <HomeForm />

  </div>
);
 
class HomeFormBase extends Component {
    constructor(props) {
        super(props);
        this.state = { };
    }
 
    render() {
        return <div></div>
        ;
    }
}

const condition = authUser => !!authUser;

const HomeForm = compose(withFirebase, withAuthorization(condition))(HomeFormBase);

export default HomePage;

export {HomeForm};