import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Modal from 'react-modal';
 
import Navigation from './components/Navigation';
import CookiePolicy from './components/CookiePolicy';
import Footer from './components/Footer';
import LandingPage from './components/Landing';
import SignUpPage from './components/SignUp';
import SignInPage from './components/SignIn';
import PasswordForgetPage from './components/PasswordForget';
import HomePage from './components/Home';
import AccountPage from './components/Account';
import AdminPage from './components/Admin';
 
import * as ROUTES from './constants/routes';
import { withAuthentication } from './components/Session';
import PrivacyPolicy from './components/PrivacyPolicy';

Modal.setAppElement('#root');

const App = () => (
  <Router>
      <Navigation />

      <Route exact path={ROUTES.COOKIE_POLICY} component={CookiePolicy} />
      <Route exact path={ROUTES.PRIVACY_POLICY} component={PrivacyPolicy} />      
      <Route path={ROUTES.LANDING+":section?/:subsection?"} component={LandingPage} />  
      <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
      <Route path={ROUTES.SIGN_IN} component={SignInPage} />
      <Route path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />
      <Route path={ROUTES.HOME+"/:uid?"} component={HomePage} />
      <Route path={ROUTES.ACCOUNT} component={AccountPage} />
      <Route path={ROUTES.ADMIN} component={AdminPage} />

      <Footer />

  </Router>
);
 
export default withAuthentication(App);