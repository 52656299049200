import React, {Component} from 'react';
import { withFirebase } from '../../../Firebase';

class Estetica extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
            title: "Estética dental"       
        };    
      }
    componentDidMount() {
        this.props.firebase.logEvent('opening_article',{title: this.state.title});
    }

    render() {
        return <div className="site-section articulo">
            <div className="block-heading-1"><h2>{this.state.title}</h2></div>
            <p>En la última década ha incrementado notablemente la cantidad de pacientes en búsqueda de tratamientos odontológicos enfocados en la estética dental. ¿Por qué? Esto se debe a que además de querer realizar cualquier restauración necesaria para la funcionalidad, también buscan que estos procedimientos les aporten una mejor apariencia estética.</p>
            <p>Por ello cada día las investigaciones odontológicas están enfocadas en encontrar las mejores técnicas para satisfacer las nuevas necesidades de la población. Esto, por supuesto ha requerido igualmente una mayor especialización de los profesionales de la odontología, creándose una nueva rama <b>enfocada en el diseño de la sonrisa. </b></p>            
            <br />
            <img src="/images/estetica.jpg" alt="" ></img>
            <br />
            <h3>¿De qué trata la estética dental?</h3>
            <p>Se trata de una especialidad dentro de la odontología que se centra en mejorar la apariencia bucal, no solo limitándose a los dientes, sino también a las otras partes que conforman la sonrisa. </p>
            <p>Entonces, ¿su labor es solo mejorar el aspecto estético? Podría decirse que sí, pero esta rama indirectamente también ayuda a <b>incrementar la funcionalidad de la mordida</b>, solucionando problemas como la mal-oclusión. </p>
            <br />       
            <h3>¿Quién puede someterse a estos procedimientos? </h3>
            <p>Este es un procedimiento que se le puede aplicar a cualquier persona que goce de una buena salud bucal. Si existe algún inconveniente de caries o enfermedad en las encías suele cumplirse en primer lugar este tratamiento para luego enfocarse en el apartado estético.</p> 
            <p>Según la condición y requerimientos de cada paciente el dentista tratante elaborará un plan de acción adecuado al caso. Establecerá una <b>proyección del resultado final</b> y los pasos y metodologías a aplicar para lograr el objetivo.</p>
            <br />   
            <h3>¿Qué implica el diseño de la sonrisa? </h3>
            <p>Cuando se habla de una sonrisa linda en estética dental, no solo se hace referencia a la dentadura, sino a todas las partes implicadas en la misma. </p>
            <h4>Labios </h4>
            <p>Comúnmente llamados <b>el “marco de la sonrisa”</b>, siempre se busca darles una correcta proporción, en conjunto con el tamaño de tu dentadura y de tu rostro. Se trata con cirugías de estética facial y tratamientos reversibles. </p>
            <h4>Encías </h4>
            <p>El tamaño y el color de las encías es un componente clave para una sonrisa más resaltante y bonita. Por ello, su alineamiento y acondicionamiento es imprescindible para un diseño natural. </p>                                   
            <h4>Dientes </h4>
            <p>Es la parte más delicada en la sonrisa. Es muy importante analizar distintos aspectos como la forma, tamaño y color de los mismos para ofrecer al paciente un <b>resultado tanto estético como funcional. </b></p>
            <br />
            <h3>¿Qué procedimientos hace un especialista en estética dental?</h3>
            <p>Un dentista especializado en esta rama puede realizar distintos tratamientos, todo depende del paciente y del objetivo que quiera lograr. </p>
            <h4>Diseño de sonrisa</h4> 
            <p>Al hablar de un diseño de sonrisa se trata de un <b>análisis cosmético enfocado en mejorar la apariencia de tu sonrisa.</b>Después del análisis se pueden planearlos pasos a seguir y los tratamientos para cumplir con la meta. Además, puede que en algunos casos se pueda requerir más de un tratamiento a la vez.</p> 
            <h4>Rehabilitación oral </h4>
            <p>Este tratamiento trata de la recuperación de una sonrisa agradable y saludable, que en algún momento presentó un problema por una fisura, o incluso la perdida de un diente. Este procedimiento de la estética dental puede que en alguna ocasión exija una prótesis fija, una removible, oclusión, entre otros, según lo que necesites. </p>
            <h4>Ortodoncia </h4>
            <p>Seguramente has visto un tratamiento de ortodoncia, o si no, lo has escuchado. Es uno de los procedimientos más usados hasta el momento para <b>alinear y retomar la funcionalidad correcta de los dientes y la mordida. </b></p>
            <h4>Blanqueamiento</h4> 
            <p>Este procedimiento de la estética dental, busca en hacer tu sonrisa más atractiva. Su finalidad es reducir la tonalidad amarilla que los dientes pueden adquirir, dándole más brillo y un tono blanco reluciente. </p>
            <h4>Cirugía maxilofacial </h4>
            <p>Este tratamiento <b>suele requerirse en los casos más profundos,</b> su finalidad si bien tiene implicaciones estéticas, está más enfocada en la salud bucal. Trata heridas, defectos de nacimiento y demás aspectos disfuncionales en la boca, dientes, faciales y maxilares. </p>
            <h4>Implantes dentales </h4>
            <p>Seguramente te preocupa por cualquier motivo perder todo o parte de un diente, pero ¿sabías que se puede solucionar? Con los implantes dentales puedes devolver la funcionalidad al momento de masticar y por supuesto la seguridad a tu sonrisa.</p>            
            </div>
    }
}

export default withFirebase(Estetica);