import React, {Component} from 'react';
import { NavLink } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';


class CookieAdvice extends Component {
    constructor(props) {
      super(props);
      this.state = {
          cookieNotAccepted: (document.cookie.indexOf('Accept_cookies') < 0)
      };
    }
    acceptCookieHandler = () => {
        document.cookie="Accept_cookies=1";
        this.setState({cookieNotAccepted: (document.cookie.indexOf('Accept_cookies') < 0)})
    }
    render() {
        if(this.state.cookieNotAccepted === true) {
            return <div id="advice" className="cookieAdvice">Utilizamos cookies propias y de terceros para mejorar nuestros servicios. Si continúa navegando, consideramos que acepta su uso.&nbsp; 
            <button className="link-button" onClick={this.acceptCookieHandler}>[ Aceptar y cerrar ]</button><NavLink exact className="link-button" to={ROUTES.COOKIE_POLICY}>[ Política de cookies ]</NavLink>
            </div>;
        } else {
            return <div></div>;
        }

    }
}

export default CookieAdvice;